@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary:#f5f5f4;
    --color-secondary:#23272a;
    --color-tertiary:#99aab5;
    --color-accent:#63ace5;
    --color-text-base: #23272a;
    --color-text-inverted: #f5f5f4;
    --color-text-accent: #63ace5;
  }
}

* {
  font-family: 'Red Hat Display', sans-serif;
}

html {
  background-color: var(--color-primary);
}

@media (min-width: 505px) {
  .card {
    max-width: 400px;
  }

  .sub-product-container {
    max-width: 575px !important;
  }

}

@media (max-width: 700px) {
  .service {
    font-size: 12px;
  }
  .modal-content {
    width: 98% !important;
  }

}

@media (max-width: 645px) {
  .table-container {
    overflow-x: scroll;
  }

  .sub-product-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .product-sub-head {
    position: revert !important;
    transform: revert !important;
    align-items: center !important;
  }

  .img-wrap {
    position: relative !important;
    transform: revert !important;
    left: revert !important;
    bottom: revert !important;
    width: 70% !important;
  }

  .img-product {
    width: 100% !important;
    left: 50% !important;
    bottom: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  /* .img-wrap {
    width: 90% !important;
    left: 50% !important;
    bottom: -70px !important;
    transform: translate(-50%, -50%) !important;
  } */

  /* .img-product {
    width: 100% !important;
    left: 50% !important;
    bottom: -120px !important;
    transform: translate(-50%, -50%) !important;
  } */

  #pu-img {
    bottom: -25% !important;
  }

  #ss-img {
    bottom: -50% !important;
  }



}

@media (min-width: 1600px) {
  .left-pointer {
    left: 15% !important;
  }

  .right-pointer {
    right: 15% !important;
  }
}

@media (min-width: 2000px) {
  .left-pointer {
    left: 20% !important;
  }

  .right-pointer {
    right: 20% !important;
  }
}

@media (max-width: 900px) {
  .product-entry {
    flex-direction: column !important;
    height: fit-content !important;
  }

  .spacer {
    height: 4px !important;
    width: 100% !important;
  }

  .phone-icon {
    font-size: 30px !important;
  }

  .call-button {
    font-size: 18px !important;
  }

  .button-head {
    font-size: 16px !important;
  }

  .test-body {
    font-size: 16px !important;
  }

  .left-pointer {
    left: 2% !important;
    font-size: 36px !important;
  }

  .right-pointer {
    right: 2% !important;
    font-size: 36px !important;
  }

}

@media (max-width: 1015px) {
  .right-container {
    margin-top: 0 !important;
  }
}

@media (max-width: 500px) {


  .footer-list {
    text-align: center;
  }

  .footer-wrap {
    flex-direction: column;
  }

  .call-button {
    font-size: 16px !important;
    width: 250px !important;
  }

  .button-head {
    font-size: 14px !important;
    width: 170px !important;
  }

  svg.star-svg {
    width: 30px !important;

  }

  .testimonials-container {
    height: 700px !important;
  }

  .reviewer {
    font-size: 16px !important;
  }

  .left-pointer {
    top: revert !important;
    bottom: 10px !important;
    font-size: 26px !important;
    left: 80% !important;
  }

  .right-pointer {
    top: revert !important;
    bottom: 10px !important;
    right: 10% !important;
    font-size: 26px !important;
  }

  .test-entry {
    height: 530px !important;
  }

}

@media (max-width: 580px) {
  .title {
    margin-left: auto;
    margin-right: auto;
    left: 0 !important;
    /* font-size: 30px !important; */
  }
}


@media (max-width: 1055px) {
  .head {
    height: 700px !important;
    flex-direction: column;
    justify-content: center !important;
    gap: 20px;
  }
  .title {
    /* font-size: 30px !important; */
  }
  .logo {
    width: 80% !important;
    min-width: 250px !important;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.menu-icon {
  width: 40px;
  height: 40px;
  position: absolute;
  left : 17px;
  top: 17px;
  fill: var(--color-primary);
}

.nav {
  transition: 0.3s;
  z-index: 1000;
}

.navButton {
  padding: 10px;
  width: 75px;
  height: 75px;
  text-align: center;
}

.phone {
  right: 20px;
  top: 29px;
  font-size: 20px;
  border-radius: 20px;
}

.title {
  font-size: calc(12px + 2vw);
  display: flex;
  align-items: center;
  flex-direction: column;
  left: 8%;
  bottom: 8%;
}

.section {
  width: 80%;
  max-width: 1300px;
}

.card {
  width: 100%;
  padding-bottom: 20px;
  /* max-width: 300px; */
  overflow: hidden;
  transition: 0.3s;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.3);
  transition: 0.3s;
}

.card:hover .card-img {
  transform: scale(1.15);
  transition: 0.3s;
}

.img-container {
  width: 100%;
  height: 50%;
  overflow: hidden;
}

.card-img {
  transition: 0.4s;
  object-position: center;
  object-fit: contain;
  aspect-ratio: 2/1;
}

.stripe {
  height: 40px;
  width: 100%;
}

.testimonials-container {
  position: relative;
  height: 500px;
  width: 100%;
}

.test-wrapper {
  position: absolute;
  overflow: hidden;
  z-index: 4;
  width: 100%;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inner {

  white-space: nowrap;
  transition: 0.5s ease-in-out;

}

.test-entry {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 500px;
  color: #fff;
}

.test-body {
  font-style: italic;
  overflow-wrap: normal;
  height: auto;
  white-space: normal;
  font-size: 20px;
}

.test-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  width: 80%;
  max-width: 1000px;
  gap: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.reviewer {
  font-weight: bold;
  font-size: 20px;
}

svg.star-svg {
  display: inline;
}

.rating {
  width: 100%;
}

.pointers {
  position: absolute;
  cursor: pointer;
  color: var(--color-primary);
  z-index: 5;
}

.left-pointer {
  font-size: 55px;
  top: 50%;
  transition: 0.4s;
  left: 5%;
  transform: translate(0, -50%);
}

.left-pointer:hover {
  color: var(--color-accent);
}

.right-pointer {
  font-size: 55px;
  right: 5%;
  transition: 0.4s;
  top: 50%;
  transform: translate(0, -50%);
}

.right-pointer:hover {
  color: var(--color-accent);
}

#map {
  height: 400px; /* The height is 400 pixels */
  width: 100%; /* The width is the width of the web page */
}

.map-container {
  width: 100%;
  max-width: 800px;
  position: relative;
}

.contact-entry {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 2rem;
}

.hours {
  margin-left: 3rem;
}

.logo {
  width: 600px;
  margin-bottom: 20px;
}

.head {
  height: 600px;
  gap: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-right: 10px;
}

.call-button {
  background-color: var(--color-primary);
  display: flex;
  gap: 20px;
  text-align: center;
  width: 280px;
  height: fit-content;
  padding: 15px;
  padding-right: 25px;
  font-size: 20px;
  border-radius: 50px;
  transition: 0.3s;
}

.call-button:hover {
  background-color: var(--color-accent);
}

.circle {
  padding: 12px;
  border-radius: 50%;
  background-color: var(--color-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 30px;
}

.phone-icon {
  font-size: 45px;
  color: var(--color-primary);
}

.button-head {
  padding: 10px 15px 10px 15px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 25px;
  text-align: center;
}

.right-container {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
}

.company-name {
  filter: drop-shadow(0px 0px 2px #000);
}

.OPEN {
  color: green;
}

.CLOSED {
  color: red;
}

.services {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.service {
  margin-bottom: 5px;
}

.nav-expanded {
  position: fixed;
  top: 0 !important;
  margin-left : 0 !important;
  border-radius: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-secondary);
  opacity: 0.95;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.expanded-options {
  display: flex;
  color: var(--color-primary);
  text-align: left;
  font-size: 52px;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  left: 5%;
  top: 98px;
  gap: 20px;
  font-weight: bold;
}

.table-container {
  overflow-x: auto;
  margin-left: auto;
  margin-right: auto;
}

/* .page-container {
  position: relative;
  min-height: 100vh;
} */

.content-wrap {
  padding-bottom: 6.5rem;    /* Footer height */
  min-height: 100vh;
}

.seperator {
  width: 2px;
  height: 150px;
  background-color: var(--color-primary);
}

footer {
  background-color: var(--color-secondary);
  color: var(--color-primary);
  margin-top: auto;
  width: 100%;
  height: fit-content;
}

.apply-button {
  background-color: #FFB700;
  font-weight: bold;
  font-size: 16px;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
}

.apply-button-md {
  background-color: #FFB700;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  white-space: nowrap;
}

.option-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background-color: var(--color-secondary);
  width: 100%;
  height: fit-content;
  max-width: 340px;
  color: var(--color-text-inverted);
}

.table-text {
  font-size: 16px;
}

.plan-no {
  font-size: 25px;
  font-weight: 800;
  color: var(--color-text-inverted);
}

.table-label {
  font-size: 14px;
}

.product-card {
  width: 295px;
  padding-bottom: 20px;
}

.products-container {
  width: 100%;
  flex-wrap: wrap;
  gap: 30px;
  border-radius: 50px;
  justify-content: center;
}

.sub-product-container {
  background-color: var(--color-secondary);
  width: 100%;
  height: 450px;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}

.img-wrap {
  width: 600px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: var(--color-primary);
  position: absolute;
  left: -300px;
  min-width: 200px;
  bottom: -80px;
}

.img-product {
  position: absolute;
  bottom: 120px;
  left: 302px;
  width: 410px;
}

.product-sub-head {
  font-size: 30px;
  font-weight: bold;
  color: var(--color-text-inverted);
  position: absolute;
  z-index: 300;
  right: 45px;
  top: 35px;
  align-items: flex-end;
}

.view-products-button {
  font-size: 20px;
  width: fit-content;
  background-color: var(--color-accent);
  color: var(--color-text-inverted);
  padding: 10px 15px 10px 15px;
  border-radius: 50px;
  transition: 0.3s;
  cursor: pointer;
}

.view-products-button:hover {
  filter: brightness(0.7);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header {
  font-weight: bold;
}

.modal-body {
  padding: 0 20px 10px 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.modal-content {
  width: 65%;
  height: fit-content;
  max-height: 650px;
  border-radius: 50px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.product-entry {
  width: 100%;
  height: 150px;
  background-color: var(--color-primary);
  display: flex;
  gap: 20px;
  padding: 10px;
}

.spacer {
  width: 4px;
  background-color: var(--color-secondary);
  height: 100%;
}

.models-wrap {
  width: 180px;
}

.modal-x {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 50px;
  cursor: pointer;
  color: var(--color-text-inverted);
}

.admin-login-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.dashboard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1300px;
  margin: auto;
  margin-top: 125px;
  margin-bottom: 23rem;
}

.updates-admin-container {
  background-color: var(--color-tertiary);
  padding: 30px;
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 50px;
}

.admin-labels {
  font-size: 20px;
  font-weight: bold;

}

.text-input {
  width: 300px
}

.img-container-admin {
  aspect-ratio: 3/2;
  max-width: 400px;
  object-fit: cover;
  max-height: 250px;
}

.update-admin {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--color-primary);
  padding: 20px;
  width: 100%;
  min-width: 730px;
}

.icon-container {
  position: absolute;
  font-size: 30px;
  padding: 10px;
  cursor: pointer;
  color: var(--color-text-inverted);
  background-color: var(--color-secondary);
}

#trash-icon {
  right: 20px;
  top: 20px;
}

#edit-icon {
  top: 80px;
  right: 20px;
}

.fin-icon {
  position: relative !important;
}

.add-icon {
  font-size: 16px !important;
  background-color: var(--color-accent);
  color: var(--color-secondary);
  font-weight: bold;
}

.admin-tabs {
  color: var(--color-text-base);
  font-size: 20px;
  font-weight: bold;
  background-color: var(--color-tertiary);
  border-radius: 5px;
  margin-bottom: 30px;
  cursor: pointer;
}

.admin-tabs-active {
  color: var(--color-text-inverted);
  background-color: var(--color-secondary);
}

.admin-page-container {
  /* height: 100vh; */
}

.header-image-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin: auto;
  width: 100%;
}

.saved-images {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  width: 100%;

}

.saved-image.selected {
  border: 5px solid var(--color-accent);
}

.saved-image {
  aspect-ratio: 3/2;
  height: 100px;
  max-width: 200px;
}

#header-img-container {
  flex-direction: column;
}

.table-textarea {
  width: 100%;
  height: 100px;
}

#financing-container {
  flex-direction: column;
}

.product-table-img {
  height: 50px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

#admin-product-container {
  flex-direction: column;
}

.services-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.admin-header-services {
  width: 10%;
}

.service-admin {
  width: fit-content;
  background-color: var(--color-primary);
  border-radius: 20px;
  padding: 10px;
}

.service-admin:hover {
  background-color: var(--color-secondary);
  color: var(--color-text-inverted);
  cursor: pointer;
}

.service-type-container {
  display: flex;
  gap: 50px;
}

.admin-service-wrapper {
  background-color: var(--color-tertiary);
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.add-service-button {
  color: var(--color-text-inverted);
  background-color: var(--color-secondary);
  padding: 10px;
  border-radius: 20px;
  margin-left: 10px;
}

.add-service-form {
  margin-top: -80px;
  margin-left: 150px;
}